<template>
  <section>
    <b-navbar shadow fixed-top :class="visible ? '' : 'hidden'">
      <template #brand>
        <b-navbar-item tag="router-link" :to="{ path: '/' }">
          <img src="/img/pyramid/logo.png" alt="Pyramid Dive Club">
        </b-navbar-item>
      </template>
      <template #start>
        <b-navbar-item class="has-text-centered" tag="router-link" :to="{ path: '/' }">
          Home
        </b-navbar-item>
        <b-navbar-item class="has-text-centered" tag="router-link" :to="{ path: '/members' }">
          Members
        </b-navbar-item>
      </template>

      <template #end class="is-fullheight">
        <div class="fixed bottom-0 w-full flex justify-center py-4 bg-gray-100 shadow-inner mr-5">
          <a
            href="mailto:contact@pyramiddiveclub.com"
            class="px-8 py-3 bg-white text-black font-semibold rounded-lg shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300 ease-in-out border border-gray-300"
          >
            Email us
          </a>
        </div>
      </template>
    </b-navbar>
  </section>
</template>

<script>

export default {
  components: {
  },
  data: () => ({
    timeout: null,
    visible: true,
    status: {
      user: {
        forceLogout: false,
        isAdmin: true
      },
      DownloadPaused: 'sync-alt',
      DownloadRate: '',
      RemainingSizeMB: 0,
      FreeDiskSpaceMB: 0
    }
  })
}
</script>

<style scoped>
.hidden { display: none }
</style>
